<template>
  <div class="legoText_cnt_about_siteContents_heb" :class="device === 'desktop' ? 'm-5' : 'm-3'">
    <div id="cnt_about_siteContents_heb_0" class="lego-row" style="">
      <div style="width: 100%;" class="lego-col" v-html="content"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FooterPages',
  props: {
    page: {
      type: Object,
      default: null,
    },
  },
  // metaInfo() {
  //   return {
  //     title: this.meta.title,
  //     meta: [
  //       { name: 'keywords', content: this.meta.keyword },
  //       { name: 'description', content: this.meta.description },
  //       { property: 'og:title', content: this.meta.title },
  //       { property: 'og:keywords', content: this.meta.keyword },
  //       { property: 'og:description', content: this.meta.description },
  //     ],
  //   };
  // },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
    }),
    content() {
      const { page } = this;
      if (!page) return '';
      return page.content;
    },
    meta() {
      const { page } = this;
      if (!page) return '';
      return page.meta;
    },
  },
  watch: {
    content() {
      if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, 0);
    },
  },
  mounted() {
    if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, 0);
  },
};
</script>
